
 .heading {
    text-transform: uppercase;
    font-size: 2rem;
    text-align: center;
  }
  .copy {
    font-size: 1.3rem;
    max-width: 75%;
    margin: auto;
    line-height: 2.5rem;

  }
