@import url("https://fonts.googleapis.com/css2?family=Jost&display=swap");

body {
  margin: 0;
  font-family: "Jost", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.badgeWrapper {
  z-index: 99;
  display: flex;
  margin-top: 25px;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
}

.playStore {
  width: 117px;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.appStore {
  height: 100%;
  margin-right: 15px;
}
