.ratesPage {
  padding-bottom: 40px;
}
.ratesSectionTop {
  padding: 40px 15px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: #19274310;
}

.heading {
  text-transform: uppercase;
  font-size: 2rem;
  text-align: center;
}

.copy {
  font-size: 1.3rem;
  padding: 0 15px;
  font-size: 1.2rem;
  margin: 0 auto;
  text-align: center;
  line-height: 1.5rem;

  @media (min-width: 768px) {
    padding: 15px;
  }
}

.sub {
  font-style: italic;
  font-size: 1rem;
}

.listItem {
  list-style: square;
  padding: 5px 0;
}

.figCaption {
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 600;

  @media (min-width: 768px) {
    font-size: 1.5rem;
  }
}

.divider,
.disclaimer {
  margin: 40px auto;
  width: 75%;
}

.highlighted {
  font-weight: 600;
}
