.history {
  padding-top: 50px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: #19274310;
  min-height: 50vh;
  font-size: 1.2rem;
  max-width: 100%;
  text-align: center;
}

.aboutUsSection {
  > h1 {
    font-size: 1.5rem;
  }

  > p {
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-transform: capitalize;
  }
}
