.footer {
  background-color: #192743;
  min-height: 50px;
  display: block;
  color: white;
  padding-top: 25px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  > figure {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media (min-width: 768px) {
      align-items: center;
    }
  }
}

.figCaption {
  border-bottom: 1px solid white;
  font-size: 24px;
  font-weight: 600;
  text-align: center;
  text-transform: uppercase;
}

.navList {
  padding: 0;
}

.contactListItem,
.navItem {
  display: flex;
  padding: 15px 0;
  list-style: none;

  > svg {
    fill: white;
    height: 15px;
    margin-right: 10px;
  }
}

.anchor {
  color: white;
  height: 100%;
  text-decoration: none;
  display: flex;
  align-items: center;
  padding: 0 15px;

  &:hover {
    text-decoration: underline;
  }
}

.moreInfoList {
  padding: 0;

  > li {
    list-style: none;
    padding: 10px 0;
  }
}

.findUsList {
  z-index: 99;
  padding: 0;
}

.playStore {
  height: 100%;
  width: 117px;
  > img {
    height: 100%;
    width: 117px;
  }
}

.appStore {
  height: 100%;
}

.legal {
  border-top: 1px solid #ccc;
  width: 100%;
  grid-column: 1 / 4;
  padding: 10px 0;
  display: flex;
  justify-content: space-around;

  > p {
    padding: 15px;
    font-size: 12px;
  }
}

.imgWrapper {
  width: 65px;
  height: 65px;
  overflow: hidden;

  & > svg {
    display: flex;
    height: 65px;
    width: 65px;
  }
}

.socialWrapper {
  list-style: none;
}
.facebook {
  margin-right: 40px;
}
