.contactUsCardWrapper {
  margin: auto;
  border-radius: 8px;
  width: 75%;
  font-size: 20px;
  padding: 20px;
}

.figCaption {
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 24px;
  line-height: 3rem;
  margin-top: 25px;
}

.contactUsList {
  display: grid;
  grid-template-columns: repeat(3, minmax(auto, 1fr));
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 25px 0;
  gap: 15px;
}

.card {
  list-style: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  border: 1px solid #cccccc60;
  border-radius: 8px;
  min-height: auto;
  padding: 20px;

  > svg {
    width: 25px;
    height: 25px;
  }

  &:hover {
    border: 1px solid black;

  }
  @media (min-width: 768px) {
    min-height: 150px;
  }
}

.anchor {
  display: none;
  @media (min-width: 768px) {
    color: black;
    text-decoration: none;
    display: block;
    font-size: 1rem;
  }
}
