.header {
  display: flex;
  height: 75px;
  margin: 0;
  justify-content: space-between;
  background-color: #192743;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  box-shadow: 0 1px 20px 7px rgba(0, 0, 0, 0.5);
}

.imgWrapper {
  width: 65px;
  position: relative;
  height: 100%;
  display: flex;
  align-items: center;
  & > svg {
    position: absolute;
    fill: white;
    stroke: white;
    left: 0;
    top: 0;
    width: 65px;
    height: 100%
    ;
  }
}

.navList {
  text-transform: uppercase;
  height: 100%;
  margin: 0;
  display: none;

  @media (min-width: 768px) {
    display: flex;
  }
}

.mobileMenuToggle {
  display: block;
  cursor: pointer;
  position: relative;
  width: 65px;
  background: transparent;
  border: none;
  @media (min-width: 768px) {
    display: none;
  }
}

.hamburgerMenu,
.hamburgerMenu::before,
.hamburgerMenu::after {
  display: block;
  background-color: #fff;
  position: absolute;
  height: 2px;
  width: 32px;
  border-radius: 3px;
}

.hamburgerMenu::before {
  content: "";
  margin-top: -8px;
}

.hamburgerMenu::after {
  content: "";
  margin-top: 8px;
}

.navItem {
  padding: 0 15px;
  list-style: none;
  height: 100%;
  font-size: 1.2rem;

  & > a {
    color: white;
    text-decoration: none;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &.current {
    font-weight: 500;
    position: relative;

    &::after {
      content: "";
      display: block;
      position: absolute;
      bottom: 5px;
      width: 60%;
      left: 20%;
      height: 2px;
      background-color: white;
      transition: 0.3s ease-in;
    }
  }
}
