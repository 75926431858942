.galleryHeading {
  padding: 50px 0;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: #19274310;
  font-size: 1.2rem;
  max-width: 100%;
  text-align: center;
}

.galleryList {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 15px;
  padding: 0 15px;
  max-width: 1150px;
  margin: auto;

  @media (min-width: 768px) {
    grid-template-columns: repeat(4
    , minmax(0, 275px));
  }
}

.imageWrapper {
  list-style: none;
  height: 250px;
  width: 100%;
  position: relative;
}

.loadingWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.buttonWrapper {
  display: flex;
  justify-content: space-around;
  margin: 25px 0 40px;
}

.button {
  padding: 15px;
  text-transform: uppercase;
  background: #192743;
  color: white;
  border-radius: 10px;
  width: 125px;
  font-weight: 600;

  &:hover{
    cursor: pointer;
  }

  &:disabled {
    opacity: .2;
  }

}

.notLoaded {
  display: none;
}

.loadedImage{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 16 / 9;
}

.loadingWrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.loadComplete {
  display: none;
}

.loading {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.loading div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #192743;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.loading div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.loading div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.loading div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

