.rateSection {
  margin: auto;
  width: 80%;
  padding: 20px;
}

.table {
  width: 100%;
}

.caption {
  font-size: 24px;
  line-height: 3rem;
  font-size: 2rem;
  text-transform: uppercase;
  font-weight: 700;
  & > span {
    font-size: 14px;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    font-size: 20px;
  }
}

.tableLegend {
  font-size: 14px;
  text-align: center;
  background-color: #19274320;
  font-weight: 600;
  text-transform: uppercase;
}

.tableRow {
  text-align: center;
  font-size: 16px;
  height: 15px;

  &:nth-child(even) {
    background-color: #19274310;
  }
}

.tableCell {
  border-radius: 5px;
  height: 40px;
}
