.featured {
  padding: 50px 15px;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  background-color: #19274310;
  text-align: center;

  > p {
    font-size: 1.3rem;
    max-width: 100%;
    padding: 0 15px;
    font-size: 1.2rem;
    text-align: center;
    @media (min-width: 768px) {
      max-width: 75%;
      margin: auto;
      line-height: 2.5rem;
    }
  }
}

.giveAndTake {
  position: relative;
  height: 500px;
  padding: 25px 0;
  overflow: hidden;
}


.featuredSection {
  padding: 40px 0;
}

.asFeaturedIn {
    background-color: #192743;
    color: white;
    padding: 25px 0;
    font-weight: 700;
}

.featuredInGrid {
  display: flex;
  flex-direction: column;
  margin: 15px 0;

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
    }

  }

.asset {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: center;
  align-self: center;
  height: 40px;
}


.videoSection {
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  background-color: #19274310;
}

.featuredFigure > figcaption {
  text-transform: uppercase;
  font-size: 2rem;
  font-weight: 600;
  margin: 25px auto;
  text-align: center;
}

.videoList, .articleList{
  padding: 0;
}

.videoListItem{
  list-style: none;
}

.articleList {

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 25px;
  }
}

.volunteer {
  margin: 40px 0;
}

.figCaption{
  font-size: 1.4rem;
  text-transform: uppercase;
  font-weight: 700;
  text-align: center;
  padding: 15px 0;

  @media (min-width: 768px) {
      font-size: 1.5rem;
    }
}

.anchor{
  color: black;
  text-decoration: none;

  &:hover{
    text-decoration: underline;
  }
}
