.articleCard {
  cursor: pointer;
  border: 1px solid #cccccc40;
  border-radius: 8px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.2rem;
  &:hover {
    border: 1px solid black;

  }
}

.anchor {
  text-align: center;
  text-decoration: none;
  color: black;

}
