.copyWrapper {
  height: 100%;
  padding: 40px 5px;
  min-height: 200px;

  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 15px 0;
  }
}

.flopped {
  direction: rtl;
  background: #19274310;
}

.heading {
  margin: auto auto 40px auto;
  direction: ltr;
  font-size: 2rem;
  text-transform: uppercase;
  text-align: center;
  border-bottom: 1px solid #19274350;
  width: 75%;
  @media (min-width: 768px) {
    width: unset;
    border-bottom: none;
    text-align: left;
    margin: auto;
  }
}

.copy {
  text-align: center;
  direction: ltr;
  margin: auto;
  min-width: 70%;
  line-height: 1.8rem;
  font-size: 1.1rem;
  max-width: 95%;

  @media (min-width: 768px) {
    text-align: left;
  }
}
