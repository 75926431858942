.mobileMenu {
  position: fixed;
  top: 70px;
  right: 15px;
  min-height: 100px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  border-radius: 4px;
  padding: 8px 16px;
  z-index: 9999;
  background: white;
}

.navList {
  padding: 0;
}

.navItem {
  list-style: none;
  text-transform: uppercase;
  height: 100%;
  min-width: 150px;
}

.anchor {
  display: block;
  padding: 15px;
  text-decoration: none;
  width: 100%;
  color: black;
}

.socialWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 25px;
}

.instagram {
  > svg {
    height: 35px;
    width: 25px;
  }
}
.facebook {
  margin-right: 25px;
  > svg {
    height: 35px;
    width: 25px;
  }
}
