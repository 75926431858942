.CTA {
  min-height: calc(85vh - 85px);
  position: relative;
  background-color: #19274310;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  overflow: hidden;
}

.copyWrapper {

  padding: 0;
  grid-column: 1 / span 5;
  text-align: center;
  @media (min-width: 768px) {
    padding: 15px 35px;
    grid-column: 1 / span 3;
    text-align: left;
  }
}

.title {
  z-index: 99;
  text-transform: uppercase;

  font-size: 3rem;
  @media (min-width: 768px) {
    font-size: 5rem;
  }
}

.copy {
  line-height: 2.2rem;
  max-width: 90%;
  font-size: 1.2rem;
  margin: auto;

  & > span {
    font-weight: 700;
    text-decoration: underline;
  }

  @media (min-width: 768px) {
    font-size: 1.4rem;
    max-width: 60%;
    margin: 0;
  }
}

.badgeWrapper {
  z-index: 99;
  display: flex;
  margin-top: 25px;
  align-items: center;
  justify-content: center;
  @media (min-width: 768px) {
    justify-content: flex-start;
  }
}

.playStore {
  width: 117px;
  > img {
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
}

.appStore {
  height: 100%;
  margin-right: 15px;
}

.imageWrapper {
  height: 100%;
  position: relative;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-row: 1;
  min-height: 500px;
  grid-column: span 5;

  @media (min-width: 768px) {
    grid-column: 4 / span 2;
    min-height: 400px;
  }
}

.mask {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, #19274310 50%, #f1f1f3 86%);
  @media (min-width: 768px) {
    background: linear-gradient(270deg, #19274310 20%, #f1f1f3 96%);
  }
}
